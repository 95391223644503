<template>
  <div class="card m-1">
    <b-navbar toggleable="lg" style="background-color: white">
      <b-navbar-brand class="text-dark" href="/customer-space" style="font-size:medium">Espace Client - <strong> {{
          enterpriseInfo.name
      }} </strong> </b-navbar-brand>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="nav align-items-center ml-auto">
        <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
          <template #button-content>
            <b-row class="text-right info" style="margin-left: 20%">
              <div class="col-md-6 d-sm-flex d-none user-nav mr-1">
                <p class="user-name font-weight-bolder mb-0 text-dark">
                  {{ customerInfo.name }}
                </p>
              </div>
              <div class="col-md-7">
                <!-- <span class="user-status text-dark">Admin</span> -->
              </div>
            </b-row>
            <b-avatar size="40" variant="light-primary" badge :src="customerInfo.logo" class="badge-minimal"
              badge-variant="success" />
          </template>

          <!-- <b-dropdown-item href="/profile" link-class="d-flex align-items-center">
            <feather-icon size="16" icon="UserIcon" class="mr-50" />
            <span>Profile</span>
          </b-dropdown-item> -->

          <!-- <b-dropdown-divider /> -->

          <b-dropdown-item @click="logout" link-class="d-flex align-items-center">
            <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
            <span>Deconnexion</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState('customer', ['customerInfo', 'enterpriseInfo'])
  },

  methods: {
    logout() {
      localStorage.clear();
      this.$router.push('/customer-login');
    }
  }
};
</script>

<style>
</style>
