<template>
  <div>
    <CustomerNavBar />
    <div class="ml-1">
      <router-link to="/customer-space">
        <span>
          <feather-icon style="margin-bottom: 5px" icon="HomeIcon" /> Accueil
        </span></router-link
      >
    </div>
    <b-card class="m-1">
      <h4>
        Validation de devis N° :
        <b class="alert alert-primary">{{ invoice.devis_num }}</b>
      </h4>
      <hr />
      <b-row>
        <b-col md="4">
          <img
            src="../../../public/validate.svg"
            class="img-fluid"
            style="max-height: 350px"
            alt=""
          />
        </b-col>
        <b-col md="8">
          <fieldset>
            <legend>
              Bonjour <b> {{ invoice.customer.name }} </b>
            </legend>
            Voici ci-dessous le lien de téléchargement de votre devis. Veuillez
            s'il-vous-plait en prendre connaissance.

            <br />
            <br />
            <b-link :href="invoice.filename_devis" target="_blank" class="mt-4">
              <feather-icon icon="FileIcon" /> Téléchager le devis
            </b-link>
          </fieldset>
          <div
            class="mt-5 p-5"
            v-if="isValidate == false && invoice.is_validate == 0"
          >
            <b-button
              class="mr-1"
              variant="outline-danger"
              @click="resetThisDevis(invoice)"
              :disabled="loading"
            >
              <b-spinner v-if="loading == true" small /> Devis erroné
            </b-button>
            <b-button @click="validateThisDevis(invoice)" :disabled="loading">
              <b-spinner v-if="loading == true" small /> Valider le devis
            </b-button>
          </div>
          <div
            class="alert alert-success mt-5 p-1"
            v-if="
              isValidate == true ||
              (invoice.is_validate == 1 && isReset == false)
            "
          >
            <p>Devis validé avec succès</p>
          </div>
          <div
            class="alert alert-danger mt-5 p-1"
            v-if="isReset == true || invoice.is_validate == 2"
          >
            <p>Devis rejeté avec succès.</p>
            <!-- <b-form>
              <b-form-group label="Veuillez nous laisser un mot">
                <b-form-textarea placeholder="Quel est l'objet de rejet de ce devis ?" />
              </b-form-group>
              <b-form-group class="text-right">
                <b-button>Envoyer</b-button>
              </b-form-group>
            </b-form> -->
          </div>
        </b-col>
      </b-row>
      <b-card-footer class="text-right">
        <p>eKoworking - Espace de travail</p>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import CustomerNavBar from "./components/CustomerNavBAr.vue";
// import pdf from 'vue-pdf'
import { mapState, mapActions } from "vuex";

export default {
  setup() {
    return {
      isValidate: false,
      isReset: false,
      show_input: false,
      loading: false,
    };
  },
  components: {
    CustomerNavBar,
    // pdf
  },
  computed: {
    ...mapState("tasks", ["invoice"]),
  },
  mounted() {
    this.getDevisCustomer(this.$route.params.num);
  },
  methods: {
    validateThisDevis(data) {
      (this.loading = true),
        (this.show_input = true),
        this.devisValidate(data.devis_num_enterprise_id, this.invoice.customer?.email)
          .then((e) => {
            this.loading = false;
            this.isValidate = true;
          })
          .catch((error) => {
            this.loading = false;
            alert("une erreur s'est produite");
          });
    },

    resetThisDevis(data) {
      (this.loading = true),
        this.devisReset(data.devis_num_enterprise_id)
          .then((e) => {
            this.isReset = true;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            alert("une erreur s'est produite");
          });
    },
    ...mapActions("tasks", ["getDevisCustomer", "devisValidate", "devisReset"]),
  },
};
</script>